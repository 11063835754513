/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { navigate } from '@reach/router';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  H3,
} from '@allthingswww/client-act-shared';
import Layout from '../components/Layout';

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row wrap;
`;

const DisplaySummaryOfEventsToBePaid = ({ name, itemsToBePaid = {}, total = 0 }) => (
  <div>
    <div>{name}</div>
    {Object.keys(itemsToBePaid).map((val, k) => (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={k}>
        <H3>
          {val}
          {' '}
          :
          {' '}
          {Currency.format(itemsToBePaid[val] / 100)}
        </H3>
      </Row>
    ))}
    <Row>
      <H3>
        Total :
        {' '}
        {Currency.format(total / 100)}
      </H3>
    </Row>
  </div>
);

const PurchasedEventItemsPage = (props) => {
  const { allItemsToBePaid } = props.location.state || [];
  let total = 0;

  if (!allItemsToBePaid) {
    if (typeof window !== 'undefined') return navigate('/members/events');
  }
  // eslint-disable-next-line no-return-assign
  _.forOwn(allItemsToBePaid, (value) => (total += value));

  return (
    <Layout title="Purchased Event items" subtitle="">
      <DisplaySummaryOfEventsToBePaid
        itemsToBePaid={allItemsToBePaid}
        total={total}
      />
    </Layout>
  );
};

export default PurchasedEventItemsPage;
